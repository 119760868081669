import React from 'react'
import Navbar from './Navbar'
import Introsection from './Home/introsection'
import Afterintro from './Home/Afterintro'
import Welcome from './Home/Welcome'
import WhyChooseus from './Home/WhyChooseus'
import Services from './Home/Services'
import Footer from './Home/Footer'


const Home = () => {
  return (
    <div>
    <Navbar/>
    <Introsection/>
    <Afterintro/>
    <Welcome/>
    <WhyChooseus/>
    <Services/>
    <Footer/>

    
    </div>
  )
}

export default Home