import React from 'react'

const Heroabt = () => {
  return (
    <div>
    <main>
      <img className="" src={require('../Images/hero/heroaboutus.jpg')} alt=""/>
    </main>
    </div>
  )
}

export default Heroabt