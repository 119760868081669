import React from 'react'
import Productsapi from './Productapi'

const ProductImage = () => {
  return (
    <div>
        
    <div className='bg-green-50 text-center p-8'>
        <h2 className='text-6xl'>Our Products</h2>
    </div>
  
         <div className="grid grid-cols-1 lg:grid-cols-3   p-16 text-center">
       {  Productsapi.map((curElem)=>{
return(
    <div key={curElem.id}>
            <img src={curElem.path} alt=''/>
            {/* <img className="w-4/4 " src={require({curElem.path})} alt=""/> */}
           
       
            <h4 className='text-lg'>{curElem.name}</h4>
        </div>
)
        
       })}
            
           
         </div>
         </div>
   
  )
}

export default ProductImage