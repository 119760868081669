import React from 'react'
import Navbar from './Navbar'
import Footer from './Home/Footer'
import HeroProduct from './Products/HeroProduct'
import ProductImage from './Products/ProductImage'
const Products = () => {
  return (
    <div>
    <Navbar/>
    <HeroProduct/>
    <ProductImage/>
    <Footer/>
    </div>
  )
}

export default Products