import React from 'react'
import AboutHero from './AboutUS/AboutHero'
// import Dierector from './AboutUS/Dierector'
import Heroabt from './AboutUS/Heroabt'
import VisionMission from './AboutUS/VisionMission'
import Footer from './Home/Footer'
import Navbar from './Navbar'

const About = () => {
  return (
    <div>
    <Navbar/>
    <Heroabt/>
    <AboutHero/>
    <VisionMission/>
    {/* <Dierector/> */}
    <Footer/>
    </div>
  )
}

export default About