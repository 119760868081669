import React from 'react'
import { NavLink } from 'react-router-dom'
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
const Navbar = () => {
  return (
    <div>
    <div class="p-1 maintop"></div>
    <div class="twotop flex justify-evenly">
      <div>
     <p><LocationOnIcon/>   Pune,Maharashtra</p>
      </div>
      <div>
        <p><CallIcon/> 9623232396</p>
      </div>
      <div>
        <p>
          <EmailIcon/> info@medrevivebiotec.com
        </p>
      </div>
    </div>
    <header>
    <input type="checkbox" id="menu-bar"/>
 <label for="menu-bar">Menu</label>
 <NavLink to={""} className="logo">
 <img className="w-4/4 " src={require('../Main/Images/MED-REVIVEBIOTECLOGO.png')} alt=""/>
 </NavLink>
 <nav class="navbar">
     <ul>
         <li><NavLink  to={"/"}>home</NavLink></li>
         <li><NavLink to={"/about"}>About us</NavLink></li>
         <li><NavLink to={"/product"}>products</NavLink></li>
         <li><NavLink to={"/contact"}>contact</NavLink></li>
        
         
     </ul>
 </nav>

</header>
    </div>
  )
}

export default Navbar