import React from 'react'

const Afterintro = () => {
  return (
    <div>
    <div className="box">
    <div className="grid grid-cols-1 lg:grid-cols-3 ">
      <div className="p-6 text-white text-center blkk">
        <h2 className="text-2xl font-bold">About Us</h2>
        <p>
        we are committed 
        towards providing better medicines and care to society and people.
        </p>
      </div>
      <div className="p-6  text-center blk">
        <h2 className="text-2xl font-bold">Products</h2>
        <p>
        We produce a comprehensive, diverse and highly complementary portfolio of specialty products.
        </p>
      </div>
      <div className="p-6 text-white text-center blkk">
        <h2 className="text-2xl font-bold">Contact Us</h2>
        <p>
        Our team will happy to give your query ansewer.
        we'll be in touch with in 24 hours.
        </p>
      </div>
    </div>
  </div>
    
    </div>
  )
}

export default Afterintro
