import React from 'react'

const WhyChooseus = () => {
  return (
    <div>
    <section >
  <div className="grid grid-cols-1 lg:grid-cols-2  p-8">
    <div className="rounded-full ">

     

      <img className="rounded-full mx-auto w-4/4 " src={require('../Images/home.jpg')} alt=""/>
    
    </div>
    <div className="">
      <h2 className="text-4xl font-medium bluetext">Why Choose Us</h2>
      <p className="text-2xl greentext">A choice that makes the difference</p>
<p>With smart facility & experienced staff, we also aim to cater to all innovators and generic companies as contract research organization along with production work. Avikem is formed with goal to provide best solutions and play active role in solving challenges in Pharma Industry.</p>
<br/>
<p>Medrevive Biotec Pvt. Ltd. takes great pride in being a WHO-GMP, ISO 9000:2000, US FDA and EU GMP certified API Manufacturing company.</p> 

</div>


  </div>
</section>
    </div>
  )
}

export default WhyChooseus