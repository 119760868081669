import React from 'react'

const HeroProduct = () => {
  return (
    <div>
    <main>
    
    <img  src={require('../Images/hero/producthero.jpg')} alt=""/>
  </main>
    </div>
  )
}

export default HeroProduct