import React from 'react'
import Formsection from './Contact/Formsection'
import HeroContact from './Contact/HeroContact'
import Footer from './Home/Footer'
import Navbar from './Navbar'

const Contact = () => {
  return (
    <div>
    <Navbar/>
    <HeroContact/>
    <Formsection/>
    <Footer/>
    </div>
  )
}

export default Contact