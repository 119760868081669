import React from 'react'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

function Services() {
  return (
    <div>
    <section className="mx-auto lg:p-16 text-center bg-slate-100">
      <h2 className="font-bold text-4xl p-2 bluetext">What Makes Us Different</h2>
      <div className="grid grid-cols-2 lg:grid-cols-4">
        <div className="text-center p-2">
          <span className="text-7xl  bg-indigo-200 rounded-full"><CheckCircleOutlinedIcon sx={{ fontSize: "80px" }}/></span>
          <h2 className="font-bold text-xl m-auto bluetext">Quality</h2>
          <p>
          If there is one attribute to which we attach the utmost importance it is quality.
          </p>
        </div>

        <div className="text-center p-2">
            <span className="text-7xl  bg-indigo-200 rounded-full"><CheckCircleOutlinedIcon sx={{ fontSize: "80px" }}/></span>
            <h2 className="font-bold text-xl m-auto bluetext">Performance</h2>
            <p>
            We are passionate to achieve goals, striving for excellence without compromising on quality and safety.
            </p>
          </div>

          <div className="text-center p-2">
            <span className="text-7xl  bg-indigo-200 rounded-full"><CheckCircleOutlinedIcon sx={{ fontSize: "80px" }}/></span>
            <h2 className="font-bold text-xl m-auto bluetext">Integrity</h2>
            <p>
            Endorsing transparent business practices and a strong work ethic across all the departments and levels in the organisation.
            </p>
          </div>

          <div className="text-center p-2">
            <span className="text-7xl  bg-indigo-200 rounded-full"><CheckCircleOutlinedIcon sx={{ fontSize: "80px" }}/></span>
            <h2 className="font-bold text-xl m-auto bluetext">
            Packaging</h2>
            <p>
            We make use of the proven techniques for packaging all the medicines. By using such advanced techniques, we aim to ensure the leakage & breakage proof delivery.
            </p>
          </div>
      </div>
    </section>
    </div>
  )
}

export default Services