import React from 'react'

const Welcome = () => {
  return (
    <div>
    <section className="text-center py-8 lg:px-16 px-4 bg-slate-100">
    <h2 className="font-bold text-4xl p-1 bluetext">
      Welcome to Medrevive Biotec Pvt. Ltd.
    </h2>
    <p>
    Medrevive Biotec Pvt. Ltd. an ISO 9001:2015 certified
              company started by highly motivated professionals is one of the
              leading contract manufacturer with headquarter located amidst the
              economic hub of India, Pune.Adapting
              quickly to the ever-evolving demands, we have emerged as one of the
              fastest growing organization in the pharmaceutical industry across the
              state. We provide a one stop solution for all fitness, wellness and
              health based needs. Our products are manufactured in the state-of-the
              art facilities conforming to the latest WHO-GMP standard. 

    </p>
 
    <p className="text-xl greentext">
    In pursuit of a healthier world for everyone to live in, we deliver reliable access to <br/> healthcare solutions and advanced sustainable operations.
    </p>

  </section>
    
    </div> 
  )
}

export default Welcome