


  const Productsapi = [
    {
      id: 1,
      path: "products/arthracal.jpg",
      name: "Arthracal Tablets",
    },
    {
        id: 2,
        path: "products/axormax.jpg",
        name: "Axormax Capsules",
      },
      {
        id: 3,
        path: "products/coxtde.jpg",
        name: "Coxide MR Tablets",
      },
      {
        id: 4,
        path: "products/hycart.jpg",
        name: "Hycart Tablets",
      },
      { 
        id: 5,
        path: "products/taxrelD.jpg",
        name: "Taxorel D Tablets",
      },
      {
        id: 6,
        path: "products/trienza.jpg",
        name: "Trienza Tablets",
      },
  ];
  export default Productsapi;