
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';

import Home from './Component/Main/Home';
import About from './Component/Main/About';
import Products from './Component/Main/Products';
import Contact from './Component/Main/Contact';
import Error from './Component/Main/Error';
import './Component/Main/style.css'
import { useState, useEffect } from 'react'
import PuffLoader from "react-spinners/ClipLoader";

function App() {
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
setLoading(true)
setTimeout(()=>{
setLoading(false)
},1000)
  },[])
  return (
    <div>
    {
      loading?
      <div style={style}>
      <PuffLoader color={'#264892'} loading={loading}    size={100} />
      </div>
      : 
      <BrowserRouter>
      <Routes >
      <Route exact path="/" element={<Home/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/product" element={<Products/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="*" element={<Error/>} />
      </Routes>
      </BrowserRouter>
      
    }
    </div>
  );
}

export default App;
