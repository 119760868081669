import React from 'react'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import AlignHorizontalRightOutlinedIcon from '@mui/icons-material/AlignHorizontalRightOutlined';
import { NavLink } from 'react-router-dom';
const Footer = () => {
  return (
    <div>
    <footer>
        <div class="mainfooter ">
          <div class="p-1 maintop"></div>
            <div class="flex flex-col lg:flex-row justify-around   p-12">
              <div class=" lg:w-6/12">
                <h2 class="text-2xl font-semibold"><AlignHorizontalRightOutlinedIcon/>  About MedRevive</h2>
                <p>Medrevive Biotec Pvt. Ltd. are continually “Innovating for better healthcare”. 
                In The Field of Pharmaceuticals and Healthcare products has only strengthened our resolve to innovate and deliver
                better value in many forms.</p>
              </div>
              <div>
                <h2 class="text-2xl font-semibold"><AlignHorizontalRightOutlinedIcon/> Quick Links</h2>
                <ul>
                
                
         <li><NavLink  to={"/"}>home</NavLink></li>
         <li><NavLink to={"/about"}>About us</NavLink></li>
         <li><NavLink to={"/product"}>products</NavLink></li>
         <li><NavLink to={"/contact"}>contact</NavLink></li>


                </ul>
              </div>
              <div>
                <h2 class="text-2xl font-semibold"><AlignHorizontalRightOutlinedIcon/>  Contact</h2>
                <h2 class="font-semibold"> <MailOutlineOutlinedIcon/> Email</h2>
                <p>info@medrevivebiotec.com</p>
                <h2 class="font-semibold"> <CallOutlinedIcon/> Contact</h2>
                <p>9623232396</p>
              </div>
            </div>
        </div>
    </footer>
    <div class="p-3 maintop">
      <p class="text-white">© Copyright Medrevive Biotec Pvt. Ltd. All Rights Reserved. @Designed by 
      
      <a href={"https://reflexarts.in/"}>Reflexarts</a> 
      
      </p>
    </div>
    </div>
  )
}

export default Footer