import React from 'react'
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarRateIcon from '@mui/icons-material/StarRate';

const VisionMission = () => {
  return (
    <div>
    <section className="vimi  bg-slate-100">
    <div className="topshape "></div>
    <div className="bottomshape "></div>
    
    <div className="grid lg:grid-cols-3 p-4">
        <div className="rounded-md bg-slate-700 text-center z-10 p-2">
            <h2 className="bggreentext text-3xl">Quality</h2>
            <p className="p-2 text-white">Quality of products is our priority. We ensure to provide the best quality of each of our products. Every product is made by using the best raw material and effective chemical compounds.</p>
        <div className="block bg-white drop-shadow-lg"><StarOutlineIcon/><StarRateIcon/><StarOutlineIcon/></div>
        </div>
    
        <div className="rounded-md bg-slate-700 text-center z-10 p-2">
            <h2 className="bggreentext text-3xl">Our Vision</h2>
            <p className="p-2 text-white">Since the starting, we are visualising ourselves to be the leading Pharma Company that keeps on growing in the market with the clear vision to treats the health of people.</p>
        <div className="block bg-white drop-shadow-lg"><StarOutlineIcon/><StarRateIcon/><StarOutlineIcon/></div>
        </div>
    
        <div className="rounded-md bg-slate-700 text-center z-10 p-2">
            <h2 className="bggreentext text-3xl">Our Mission</h2>
            <p className="p-2 text-white">Our company is on the mission to treat, prevent and cure health problem related to the neuropsychiatry disease . Securing the lives of people and spreading the healthy environment all around us, is our core objective.</p>
        <div className="block bg-white drop-shadow-lg"><StarOutlineIcon/><StarRateIcon/><StarOutlineIcon/></div>
        </div>
    
    
    </div>
    
    
        </section>
    </div>
  )
}

export default VisionMission