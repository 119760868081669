import React from 'react'

const HeroContact = () => {
  return (
    <div>
    <main>
    <img className="" src={require('../Images/hero/contacthero.jpg')} alt=""/>
    </main>

    </div>
  )
}

export default HeroContact