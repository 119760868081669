import React from 'react'


const AboutHero = () => {

  return (
    <div>
    <section >
      <div className="grid grid-cols-1 lg:grid-cols-2   p-8">
       
        <div className="">
          <h2 className="text-4xl font-medium bluetext">About Medrevivebiotec</h2>
          <p className="text-2xl greentext">A choice that makes the difference</p>
          <p>
    Medrevive Biotec Pvt. Ltd. an ISO 9001:2015 certified
              company started by highly motivated professionals is one of the
              leading contract manufacturer with headquarter located amidst the
              economic hub of India, Pune.Adapting
              quickly to the ever-evolving demands, we have emerged as one of the
              fastest growing organization in the pharmaceutical industry across the
              state. We provide a one stop solution for all fitness, wellness and
              health based needs. Our products are manufactured in the state-of-the
              art facilities conforming to the latest WHO-GMP standard. 

    </p>
    <br/>
    <p>


We assure for every single product that comes out of our brand has our guarantee of most superior quality.</p> 
    <br/>
    
    </div>
    <div className="rounded-full ">
    
        
        <img className="rounded-full mx-auto w-4/4" src={require('../Images/home.jpg')} alt=""/>
      
      </div>
    
      </div>
    </section>
  
    </div>
  )
}

export default AboutHero