import React from 'react'

const Introsection = () => {
  return (
    <div>
    <main>
  
    <img className="w-4/4 " src={require('../Images/hero/herohome.jpg')} alt=""/>
  </main>
    </div>
  )
}

export default Introsection